import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Container } from 'components/common';
import logo from 'assets/illustrations/hausfee-logo.svg';
import NavbarLinks from '../NavbarLinks';
import { NavbarWrapper, Brand, Thumbnail } from './styles';

const Navbar = () => (
  <NavbarWrapper as={Container}>
    <Brand as={Link} to="/">
      <Thumbnail>
        <img src={logo} alt="RD Hausfee logo" />
      </Thumbnail>
    </Brand>
    <NavbarLinks desktop />
  </NavbarWrapper>
);

export default Navbar;
