module.exports = {
  defaultTitle: 'RD HAUSFEE',
  logo: '',
  author: 'Luka Grgic',
  url: 'https://rd-hausfee.at',
  legalName: 'RD HAUSFEE',
  defaultDescription: 'RD HAUSFEE',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
    google: '',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: 'Pressbaum',
    region: 'Hauptstraße 61/Haus 1/ Top 6',
    country: 'Austira',
    zipCode: '3021',
  },
  contact: {
    email: 'office@rd-hausfee.at',
    phone: '+36641661590',
  },
  foundingDate: '2020',
};
