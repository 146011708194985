import styled from 'styled-components';

export const Heading = styled.h1`
  text-align: left;
  color: green;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #01b5ae;
  text-align: right;
  padding-right: 20px;
`;
