import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import { Wrapper, MenuWrapper, Button } from './styles';

const NavbarLinks = ({ desktop }) => {
  function getContactForm() {
    const id = document.getElementById('kontaktButton');
    if (id) {
      document.getElementById('homeButton').click();
      setTimeout(function() {
        document.getElementById('kontaktButton').click();
      }, 0);
    }
  }

  function getKontakt() {
    let path;
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      path = window.location.pathname === '/';
    }

    if (path) {
      return (
        <AnchorLink href="#contact">
          <Button id="kontaktButton">KONTAKT</Button>
        </AnchorLink>
      );
    }
    return (
      <Button id="kontaktButton" onClick={getContactForm}>
        KONTAKT
      </Button>
    );
  }

  return (
    <Wrapper desktop={desktop}>
      <MenuWrapper>
        <AnchorLink href="#about">
          <Link to="/">
            <Button id="homeButton">HOME</Button>
          </Link>
        </AnchorLink>
      </MenuWrapper>
      <MenuWrapper>
        <AnchorLink href="#projects">
          <Link to="/gewerbekunden">
            <Button>GEWERBEKUNDEN</Button>
          </Link>
        </AnchorLink>
      </MenuWrapper>
      <MenuWrapper>
        <AnchorLink href="#reviews">
          <Link to="/privatkunden">
            <Button>PRIVATKUNDEN</Button>
          </Link>
        </AnchorLink>
      </MenuWrapper>
      <MenuWrapper>
        <AnchorLink href="#reviews">
          <Link to="/about">
            <Button>ABOUT</Button>
          </Link>
        </AnchorLink>
      </MenuWrapper>
      <MenuWrapper>{getKontakt()}</MenuWrapper>
      {/* <ToggleTheme /> */}
    </Wrapper>
  );
};

export default NavbarLinks;
