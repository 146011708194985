import footerIllustration from 'assets/illustrations/footer.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  // padding: 28rem 0 4rem 0;
  // background-image: url(${footerIllustration});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background: #f4f7f0;
  padding-top: 10px;

  .madeBy {
    float: left;
    font-family: Lato;
    color: #8d9090;
    padding-left: 20px;
    padding-top: 10px;
    display: flex;
    @media (max-width: 680px) {
        font-size: small;
        width: max-content;
        padding-left: 15px;
    }
  }

  .flowRoot {
    @media (max-width: 680px) {
      display: flow-root;
    }
  }

  .grey {
    color: #8d9090;
  }

  .impressum {
    color: #8d9090;
    float: right;
    padding-right: 20px;
  } 

  // @media (max-width: 1960px) {
  //   padding: 14rem 0 4rem;
  // }

  .displayFlex {
    display: inital;
    @media (max-width: 680px) {
      display: inline-flex;
    }
  }

  .minContent {
    @media (max-width: 680px) {
      max-width: min-content;
    }
  }

  .paddingLeft15 {
    @media (max-width: 680px) {
      padding-left: 15px;
    }
  }

  .inlineFlex {
    @media (max-width: 680px) {
      display: inline-flex;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: inherit;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 53px;

  // @media (max-width: 960px) {
  //   flex-direction: column;
  //   width: 80%;
  // }

  // @media (min-width: 993px) {
  //   width: 58%;
  // }

  // @media (max-width: 601px) {
  //   width: 90%;
  //   padding-top: 80px;
  // }

  color: #707070;

  // .positionRelative {
  //   position: relative;
  //   align-items: center;
  // }

  // .positionAbsolute {
  //   position: absolute;
  //   right: 23vw;
  //   top: 15vw;
  //   @media screen and (max-width: 600px) {
  //     right: 3vw;
  //     top: 24vw;
  //     width: 50vw;
  //   }

  //   @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  //     right: 14vw;
  //     top: 20vw;
  //   }
  // }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  h2,
  a,
  span {
    color: #94c152;
  }
  text-align: center;

  .hausfeeLogo {
    display: inline-flex;
    padding-bottom: 30px;
    height: 200px;
    widht: 200px;
    float: right;
    border-right: 4px solid #bfa4af;
    padding-right: 20px;
    @media (max-width: 680px) {
      height: 150px;
    }
  }

  .borderRight {
    height: 100%;
    border-right: 2px solid #707070;
    padding-top: 50px;
  }

  .phoneContainer {
    float: left;
    display: flex;
    @media (max-width: 680px) {
      justify-content: center;
      float: none;
    }
  }

  .emailContainer {
    float: right;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
      padding-bottom: 10px;
    }
  }

  .inlineFlex {
    @media (max-width: 680px) {
      display: inline-flex;
    }
  }

  .containerIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mailIcon {
    margin-bottom: 0px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h2.email,
  h2.phone {
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    font-family: Lato;
    color: #6b6b65;
  }

  .logoText {
    font-size: 44px;
    font-family: Lato;
    color: #6b6b65;
    text-align: left;
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }

    @media (min-width: 601px) and (max-width: 960px) {
      font-size: 24px;
    }
  }

  .logoAlign {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
