import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  background-color: #c0a6b0;
  opacity: 69%;
  width: 100%;
  position: fixed;
  z-index: 999;
  align-items: center;
  top: 0px;
`;

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
			display: block;
			z-index: 4;	
	`}
`;
