import styled from 'styled-components';

export const MenuWrapper = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const Button = styled.div`
  font-family: Lato;
  border: 2px solid;
  color: #ffff;
  border-color: #ffff;
  border-radius: 7px;
  width: fit-content;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
  &:hover {
    background: white;
    color: #c6b7c1;
    transition: 0.3s ease-in;
  }
`;

export const Wrapper = styled.div`
  a {
    color: #6f6f6f;
    text-decoration: none;

    @media (max-width: 960px) {
      color: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;
