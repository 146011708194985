import styled from 'styled-components';

export const NavbarWrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1500px !important;
`;

export const Brand = styled.a`
  color: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};

  @media (max-width: 960px) {
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? '#fff' : '#fff')};
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 80px;
    margin-bottom: 0px;
  }
`;
