import React from 'react';
import { Row, Col, Stack, Container } from 'react-bootstrap';
import hausfeeLogo from 'assets/illustrations/RDHAUSFEE_logo.png';
import mailIcon from 'assets/illustrations/MAIL-ICON.svg';
import phoneIcon from 'assets/illustrations/PHONE-ICON.svg';
import { Wrapper, Details, FooterWrapper } from './styles';

export const Footer = () => (
  <Wrapper>
    <FooterWrapper>
      <Details>
        <Container>
          <Row>
            <Col>
              <img className="hausfeeLogo" src={hausfeeLogo} alt="Hausfee logo" />
            </Col>
            <Col className="logoAlign">
              <p className="logoText">
                BRINGT IHR HAUS <br /> & SIE ZUM STRAHLEN.
              </p>
            </Col>
          </Row>
          <Row xs={1} md={2}>
            <Col className="inlineFlex">
              <div className="emailContainer">
                <img className="mailIcon" src={mailIcon} alt="Email" />
                <h2 className="email">office@rd-hausfee.at</h2>
              </div>
            </Col>
            <Col className="inlineFlex">
              <div className="phoneContainer">
                <img className="mailIcon" src={phoneIcon} alt="Phone" />
                <h2 className="phone">+43 664 166 1589</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </Details>
    </FooterWrapper>
    {/* <Row xs={1} md={2} className="displayFlex">
      <Col>
        <span className="madeBy">
          {new Date().getFullYear()} Made by &nbsp;
          <a className="grey" href="https://lukagrgic.com/" rel="noopener noreferrer" target="_blank">
            Luka
          </a>
          <p className="grey">&nbsp;| screendesign by &nbsp;</p>
          <a className="grey" href="https://forest-view.art" rel="noopener noreferrer" target="_blank">
            forest view design
          </a>
        </span>
      </Col>
      <Col className="inlineFlex minContent paddingLeft15">
        <a className="impressum" href="/impressum" rel="noopener noreferrer" target="_self">
          Datenschutz
        </a>
        <br />
        <a className="impressum" href="/impressum" rel="noopener noreferrer" target="_self">
          Impressum
        </a>
      </Col>
    </Row> */}
    <Stack direction="horizontal" gap={3} className="flowRoot">
      <div>
        <span className="madeBy">
          {new Date().getFullYear()} Made by &nbsp;
          <a className="grey" href="https://lukagrgic.com/" rel="noopener noreferrer" target="_blank">
            Luka
          </a>
          <p className="grey">&nbsp;| screendesign by &nbsp;</p>
          <a className="grey" href="https://forest-view.art" rel="noopener noreferrer" target="_blank">
            forest view design
          </a>
        </span>
      </div>
      <div className="ms-auto">
        <a className="impressum" href="/impressum" rel="noopener noreferrer" target="_self">
          Datenschutz
        </a>
        <br />
        <a className="impressum" href="/impressum" rel="noopener noreferrer" target="_self">
          Impressum
        </a>
      </div>
    </Stack>
    {/* <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links> */}
  </Wrapper>
);
